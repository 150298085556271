@import '/apps/wt/webtools/sparta_build_server/tmp/EE81542B/artifacts/components/utilities/global/sparta-style-utility/4.9.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;




[data-sparta-container] .seo-overview-bottom-image-module {

  margin-top: 30px;
  @media #{$medium-only} {
    margin-top: 60px;
  }
  @media #{$large-up} {
    margin-top: 38px;
  }
  > .row {
    > .column {
      padding: 0;
    }
    padding: 0;
  }

  .picture-box {
    picture {
      display: block;
      position: relative;
      left: auto;
      transform: translateX(0);
    }
  }
}

[data-sparta-container] .global-footer-module .footer--consumer {
  margin-top: 0px;
}
